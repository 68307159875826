/* --------------------------------------
@Author: ThemeMascot
@URL: http://themeforest.net/user/ThemeMascot
----------------------------------------- */
/*
 * rtl.less
 * -----------------------------------------------
*/

html,
body { direction: rtl; }

html[dir="rtl"] {
	.icon-box.left a{margin-left:0px; margin-right: 0;}
	.attorney .content { padding-right: 30px;}
	.section-title.title-border {
	  border-left: 0;
	  border-right: 4px solid #bf9e58;
	}
	.panel-group .panel-title a::after {
		left: auto;
	}
	/*
	.boxed-layout {
		.header {
			margin-left: 0;
			margin-right: 0;
		}
	}
	*/
	.blog-posts .post .entry-content .post-date.right {
		right: auto;
	}
	.checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
	  margin-right: -20px;
	}
	.checkbox-inline, .radio-inline {
	  padding-right: 20px;
	}
	.owl-controls .owl-prev {
	  left: 0;
	  right: auto;
	}
	.owl-controls .owl-next {
	  right: 0;
	  left: auto;
	}
	.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev {
	  right: auto;
	  left: 45px;
	}
	.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next {
	  right: auto;
	}
	.blog-posts .post.style1 .entry-header .entry-date.entry-date-absolute {
	  left: auto;
	}
	.post .entry-title {
		margin-right: 15px !important;
	}
	#flickr-feed img {
	  float: right;
	}
	.menuzord-menu {
	  float: right;
	}
	.menuzord-menu.menuzord-right {
	  float: left;
	}
	.menuzord-brand {
	  margin-right: 0;
	  margin-left: 30px;
	}
	.menuzord-menu > li {
	  float: right;
	}

	/* timeline */
	.cd-timeline::before{right:18px;}
	.cd-timeline-img{right:0;}
	.cd-timeline-img img{right:50%;margin-right:-12px;}
	.cd-timeline-content{margin-right:60px;}
	.cd-timeline-content .cd-read-more{float:left;}
	.cd-timeline-content .discription{float:right;}
	.cd-timeline-content::before{left:100%;}
	@media only screen and (min-width:1170px) {
	    .cd-timeline::before{right:50%;margin-right:-2px;}
	    .cd-timeline-block:nth-child(odd) .cd-timeline-content{text-align:left;}
	    .cd-timeline-img{right:51%;margin-right:-30px;}
	    .cd-timeline-content{margin-right:0;}
	    .cd-timeline-content::before{right:100%;border-right-color:white;}
	    .cd-timeline-content .cd-read-more{float:right;}
	    .cd-timeline-content .discription{right:122%;padding:23px 33px 0 19px;text-align:right;}
	    .cd-timeline-content .discription img{margin-right:-3px;}
	    .cd-timeline-block:nth-child(even) .cd-timeline-content{float:left;text-align:right;}
	    .cd-timeline-block:nth-child(even) .cd-timeline-content::before{right:auto;left:100%;border-left-color:white;}
	    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more{float:left;}
	    .cd-timeline-block:nth-child(even) .cd-timeline-content .discription{right:auto;left:122%;}
	}

	/* flexslider */
	.flexslider {
		margin: 0;
		padding: 0;
		direction: ltr;
	}
	
	/* vertical nav */
	.vertical-nav .main-content, .vertical-nav .footer {
	  	margin-left: auto;
		@media only screen and (max-width : 900px) {
			margin-right: auto !important;
		}
	}
	.vertical-nav .menuzord-menu li .indicator {
		float: left;
		left: 0;
		right: auto;
		margin-left: 6px;
		@media only screen and (max-width : 900px) {
		  border-left: 0 none;
		  border-right: 1px solid;
		}
	}
	.vertical-nav  .menuzord-responsive .menuzord-menu li ul.dropdown li .indicator {
	  right: auto;
	}
	.vertical-nav .menuzord-menu ul.dropdown, .vertical-nav .menuzord-menu .megamenu {
		left: auto !important;
		right: 100% !important;
	}
	.vertical-nav.vertical-nav-right .menuzord-menu ul.dropdown, .vertical-nav.vertical-nav-right .menuzord-menu .megamenu {
	  left: 100% !important;
	  right: auto !important;
	}
	.stylish-header:after {
	    border-left: 105px solid transparent;
	    border-right: 0px solid transparent;
	    left: -42%;
	}
	.menuzord-responsive .menuzord-menu li .indicator {
		right: auto;
		left: 0;		
	}
	.menuzord .showhide {
		float: left;
	}
	/* bx slider control */
	.bx-controls .bx-controls-direction .bx-next {
	  left: 0;
	  right: auto;
	}
	.post .entry-meta.meta-absolute {
		right: auto !important;
	}
	.causes .thumb .donate-piechart {
	  right: auto;
	}
	.event .event-content {
	  margin-right: 10px;
	}
	.home-boxes .bg-icon {
		right: auto;
		left: 0;
		transform: rotate(40deg);
	}
	.causes .progress-item .progress-bar .percent {
      left: -10px;
      right: auto;
    }
    .course-details .price-tag {
      border-radius: 0 12px 0 0;
      left: 0;
      right: auto;
    }
    .testimonial.style2 .testimonial-wrapper .content i {
      left: auto;
      right: 30px;
    }
    .testimonial.style2 .testimonial-wrapper .thumb {
      left: 40px;
      right: auto;
    }
    .floating {
      float: none;
    }
}
